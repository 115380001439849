import { Restricted } from 'components/Restricted';
import { appSettings } from 'config/app.settings';
import { BillingCustomerContext } from 'context/billing-customer.context';
import InvoiceChangeType from 'pages/Invoices/pages/InvoiceChangeType/InvoiceChangeType';
import OutageOverview from 'pages/Outage/pages/OutageOverview';
import EmailSettings from 'pages/Products/pages/InternetProducts/pages/EmailSettings';
import ServiceSupportCaseDetail from 'pages/Service/SupportCase/pages/Detail/SupportCaseDetail';
import ServiceSupportCaseDetailNewComment from 'pages/Service/SupportCase/pages/Detail/SupportCaseDetailNewComment';
import ServiceSupportCaseOverview from 'pages/Service/SupportCase/pages/Overview/SupportCaseOverview';
import React, { Suspense, lazy, useContext, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { throttle } from 'utils/throttle';

import BaseLayout from './components/BaseLayout';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Loader } from './components/Loader';
import { PageNotFound } from './components/PageNotFound';
import { ProviderProvider } from './components/ProviderProvider';
import { SplashScreen } from './components/SplashScreen';
import { PRODUCTS, USAGE } from './config/access-state-features.config';
import { AuthContext } from './context/auth/auth.context';
import { OpenOrderProvider, OrderProvider } from './context/order/order.context';
import { PermissionSettings } from './models';
import { FEATURES } from './models/features.model';
import { Error } from './pages/Error';
import { PaymentArrangementOverview } from './pages/Invoices/pages/PaymentArrangement';
import { PaymentRedirect } from './pages/Invoices/pages/PaymentRedirect';
import { PaymentResult } from './pages/Invoices/pages/PaymentResult';
import Workaround from './pages/Workaround';
import { tagPageVisit } from './utils/analytics';

const Dashboard = lazy(() => import('pages/Dashboard' /* webpackChunkName: "Dashboard" */));
const Usage = lazy(() => import('pages/Usage' /* webpackChunkName: "Usage" */));
const Products = lazy(() => import('pages/Products' /* webpackChunkName: "Products" */));
const ContactDetails = lazy(() => import('pages/ContactDetails' /* webpackChunkName: "ContactDetails" */));
const Invoices = lazy(() => import('pages/Invoices' /* webpackChunkName: "Invoices" */));
const InvoicePayment = lazy(
  () => import('pages/Invoices/pages/InvoicePayment' /* webpackChunkName: "InvoicePayment" */)
);
const Entertainment = lazy(() => import('pages/Entertainment' /* webpackChunkName: "Entertainment" */));

const OrderDetailsUnauthenticated = lazy(() =>
  import(
    'pages/ContactDetails/pages/Orders/pages/OrderDetails' /* webpackChunkName: "OrderDetailsUnauthenticated" */
  ).then((m: any) => ({ default: m.OrderDetailsAuthenticated }))
);
const UnauthenticatedAppointmentRoutes = lazy(() =>
  import('pages/ContactDetails/contact-details.routes' /* webpackChunkName: "UnauthenticatedAppointmentRoutes" */).then(
    (m: any) => ({ default: m.UnauthenticatedAppointmentRoutes })
  )
);
const UnauthenticatedOutBoundOrderStatus = lazy(
  () =>
    import(
      'pages/ContactDetails/pages/Orders/pages/UnauthenticatedOutBoundOrderStatus/UnauthenticatedOutBoundOrderStatus' /* webpackChunkName: "UnauthenticatedOutBoundOrderStatus" */
    )
);

const UnauthenticatedUnsubscribe = lazy(() => import('pages/Unsubscribe/routes'));
const UnauthenticatedSubscribe = lazy(() => import('pages/Subscribe/routes'));

const UnauthenticatedIdealPaymentStatus = lazy(
  () =>
    import(
      'pages/IdealPaymentStatus/UnauthenticatedIdealPaymentStatus' /* webpackChunkName: "UnauthenticatedIdealPaymentStatus" */
    )
);

const unauthenticatedRoutes = [
  '/orders',
  '/orderstatus',
  '/betaalstatus',
  '/monteursafspraken',
  '/afmelden',
  '/aanmelden',
];
const isUnauthenticatedRoute = (path: string): boolean => unauthenticatedRoutes.some((route) => path.includes(route));

const PageVisitAnalytics = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { pathname } = useLocation();
  const { activeBcIdHash, completed } = useContext(BillingCustomerContext);

  const throttledTagPageVisit = () =>
    throttle(() => {
      tagPageVisit({
        isAuthenticated,
        billingCustomerHashedId: activeBcIdHash,
      });
    });

  useEffect(() => {
    if (!completed) {
      if (isUnauthenticatedRoute(pathname)) throttledTagPageVisit();
      return;
    }

    throttledTagPageVisit();
  }, [pathname, isAuthenticated, completed]);

  return null;
};

// Interface for RouteWithFeaturetoggleProps
// Note that feature enforces only the use of 4 specific features which can be disabled
interface RouteWithFeatureToggleProps {
  MainComponent: React.FunctionComponent<any> | React.ComponentClass;
  feature:
    | FEATURES.PAGE_CONTACT_DETAILS
    | FEATURES.PAGE_INVOICES
    | FEATURES.PAGE_PRODUCTS
    | FEATURES.PAGE_USAGE
    | FEATURES.PAGE_ENTERTAINMENT;
  permissions?: PermissionSettings;
}

// Beautiful component which allows permissions, feature and regular route properties
// This component will show a specific component when the page is not accessible (See components/FatureToggleEmptyPage)
// If the permissions are denied however, the route will instead render the PageNotFoundPage
const RouteWithFeatureTogglePage = ({ permissions, MainComponent, feature }: RouteWithFeatureToggleProps) => {
  return (
    <Restricted feature={feature} permissions={permissions}>
      {(allowed) => (allowed ? <MainComponent /> : <PageNotFound />)}
    </Restricted>
  );
};

// Simple component which scrolls to top every time the url changes
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => window.scrollTo(0, 0), [pathname]);
  return null; // don't render anything!
};

// Authenticated routes are all routes which require the user to be logged in.
// Other routes should be defined in the "Routing" component
const AuthenticatedRoutes = () => {
  // calling requireAuthentication will kick off the authentication flow,
  // either logging in the user or redirecting to login in account
  const { isAuthenticated, hasErrors, errorType, requireAuthentication } = useContext(AuthContext);
  const { completed, hasError: billingCustomerHasError, errorCode } = useContext(BillingCustomerContext);

  // Require authentication once
  useEffect(() => {
    requireAuthentication();
  }, []);

  // When the user is NOT authenticated and billingCustomerContainer is not fully completed?
  // show loader as all the containers used in Authenticated routes need the billingCustomerContainer and are using it statically
  if (!isAuthenticated || !completed || billingCustomerHasError) {
    return <SplashScreen hasError={hasErrors || billingCustomerHasError} errorType={errorType || errorCode} />;
  }

  return (
    <ProviderProvider providers={[OrderProvider, OpenOrderProvider]}>
      <Routes>
        <Route
          path="/overzicht"
          element={
            <React.Suspense fallback={<>...</>}>
              <Dashboard />
            </React.Suspense>
          }
        />
        <Route
          path="/facturen/:invoiceId/betalen"
          element={
            <React.Suspense fallback={<Loader />}>
              <InvoicePayment />
            </React.Suspense>
          }
        />
        <Route path="/facturen/type-factuur-wijzigen" element={<InvoiceChangeType />} />
        <Route path="/facturen/betaalregeling" element={<PaymentArrangementOverview />} />
        <Route
          path="/facturen/*"
          element={
            <React.Suspense fallback={<Loader />}>
              <RouteWithFeatureTogglePage MainComponent={Invoices} feature={FEATURES.PAGE_INVOICES} />
            </React.Suspense>
          }
        />
        <Route path="/betaling" element={<PaymentResult />} />
        <Route path="/betaling-redirect" element={<PaymentRedirect />} />
        <Route
          path="/verbruik/*"
          element={
            <React.Suspense fallback={<Loader />}>
              <RouteWithFeatureTogglePage MainComponent={Usage} feature={FEATURES.PAGE_USAGE} permissions={USAGE} />
            </React.Suspense>
          }
        />
        <Route
          path="/producten/internet/emailadres-instellen/:index"
          element={
            <React.Suspense fallback={<Loader />}>
              <EmailSettings />
            </React.Suspense>
          }
        />
        <Route
          path="/producten/*"
          element={
            <React.Suspense fallback={<Loader />}>
              <RouteWithFeatureTogglePage
                MainComponent={Products}
                feature={FEATURES.PAGE_PRODUCTS}
                permissions={PRODUCTS}
              />
            </React.Suspense>
          }
        />
        <Route path="/workaround/:context" element={<Workaround />} />
        <Route
          path="/gegevens/*"
          element={
            <React.Suspense fallback={<Loader />}>
              <RouteWithFeatureTogglePage MainComponent={ContactDetails} feature={FEATURES.PAGE_CONTACT_DETAILS} />
            </React.Suspense>
          }
        />
        <Route path="/storingen" element={<OutageOverview />} />
        <Route path="/service/case/overzicht" element={<ServiceSupportCaseOverview />} />
        <Route path="/service/case/:reference" element={<ServiceSupportCaseDetail />} />
        <Route path="/service/case/:reference/nieuwe-opmerking" element={<ServiceSupportCaseDetailNewComment />} />
        <Route
          index
          element={
            <React.Suspense fallback={<Loader />}>
              <Dashboard />
            </React.Suspense>
          }
        />
        <Route
          path="/entertainment/*"
          element={
            <React.Suspense fallback={<Loader />}>
              <RouteWithFeatureTogglePage MainComponent={Entertainment} feature={FEATURES.PAGE_ENTERTAINMENT} />
            </React.Suspense>
          }
        />
        <Route path="*" element={PageNotFound()} />
      </Routes>
    </ProviderProvider>
  );
};

export const AppRouter = () => {
  const basename = appSettings.ENV_PUBLIC_URL.endsWith('/')
    ? appSettings.ENV_PUBLIC_URL.slice(0, -1)
    : appSettings.ENV_PUBLIC_URL;

  return (
    // Before the baseLayout would wrap all components in routes
    // This is now defined around specific routes,
    // or authenticated routes as this would otherwise be shown to Derby users before they are redirected
    <ErrorBoundary errorComponent={<Error />}>
      <BrowserRouter basename={basename}>
        <PageVisitAnalytics />
        <Suspense
          fallback={
            // BaseLayout can be wrapped around Suspense loader as it only
            // applies to pages which are lazy loaded thus the user has access.
            <>
              <BaseLayout>
                <Loader />
              </BaseLayout>
            </>
          }>
          <ScrollToTop />
          <BaseLayout>
            <Routes>
              <Route path="/welkom" element={<Navigate replace to="/overzicht" />} />
              <Route path="/welkom.html" element={<Navigate replace to="/overzicht" />} />
              <Route path="/factuur" element={<Navigate replace to="/facturen" />} />
              <Route path="/factuur.html" element={<Navigate replace to="/facturen" />} />
              <Route path="/producten.html" element={<Navigate replace to="/producten/overzicht" />} />
              <Route
                path="/producten/televisie/extrachannel-mainpage"
                element={<Navigate replace to="/producten/televisie" />}
              />
              <Route
                path="/producten/internet/safeonline"
                element={<Navigate replace to="/producten/internet" state={{ action: 'fetch-safe-online' }} />}
              />
              <Route
                path="/orders/*"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <OrderDetailsUnauthenticated />
                  </React.Suspense>
                }
              />
              <Route
                path="/orderstatus/*"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <UnauthenticatedOutBoundOrderStatus />
                  </React.Suspense>
                }
              />
              <Route
                path="/betaalstatus/payment/:paymentId/transaction/:transactionId"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <UnauthenticatedIdealPaymentStatus />
                  </React.Suspense>
                }
              />
              <Route
                path="/monteursafspraken/*"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <UnauthenticatedAppointmentRoutes />
                  </React.Suspense>
                }
              />
              <Route
                path="/afmelden/*"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <UnauthenticatedUnsubscribe />
                  </React.Suspense>
                }
              />
              <Route
                path="/aanmelden/*"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <UnauthenticatedSubscribe />
                  </React.Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <AuthenticatedRoutes />
                  </React.Suspense>
                }
              />
            </Routes>
          </BaseLayout>
        </Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
